import axios from 'axios';
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
export function request(option) {
    const instance = axios.create({
        baseURL: '/api/jnjq',
        // baseURL: 'http://192.168.3.105',
        timeout: 5000,
    });
    // 添加请求拦截器
    instance.interceptors.request.use(function (config) {
        // 在发送请求之前做些什么
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration:0,
        });
        return config;
    }, function (error) {
        Toast.clear()

        // 对请求错误做些什么
        return Promise.reject(error);
    });

    // 添加响应拦截器
    instance.interceptors.response.use(function (response) {
        // 对响应数据做点什么
        Toast.clear()
        return response.data;
    }, function (error) {
        Toast.clear()

        // 对响应错误做点什么
        return Promise.reject(error);
    });

    return instance(option)
}